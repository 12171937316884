$(document).ready(function () {

    // Cart
    //----------------------------------------//

    $("#cart").hoverIntent({
        sensitivity: 3,
        interval: 60,
        over: function () {
            $('.cart-list', this).fadeIn(200);
            $('.cart-btn a.button', this).addClass('hovered');
        },
        timeout: 220,
        out: function () {
            $('.cart-list', this).fadeOut(100);
            $('.cart-btn a.button', this).removeClass('hovered');
        }
    });

    // Initialise Superfish
    //----------------------------------------//

    $('ul.menu').superfish({
        delay: 400,                    // delay on mouseout
        speed: 200,                    // faster animation speed
        speedOut: 100,                    // speed of the closing animation
        autoArrows: true                    // disable generation of arrow mark-up
    });

    //$('a').magnificPopup({
    //    items: {
    //        src: $('<div class="white-popup">Dynamically created popup</div>'),
    //        type: 'inline'
    //    }
    //});

    var pxShow = 600; // height on which the button will show
    var fadeInTime = 400; // how slow / fast you want the button to show
    var fadeOutTime = 400; // how slow / fast you want the button to hide
    var scrollSpeed = 400; // how slow / fast you want the button to scroll to top.

    $(window).scroll(function () {
        if ($(window).scrollTop() >= pxShow) {
            $("#backtotop").fadeIn(fadeInTime);
        } else {
            $("#backtotop").fadeOut(fadeOutTime);
        }
    });

    $('#backtotop').click(function () {
        $('html, body').animate({scrollTop: 0}, scrollSpeed);
        return false;
    });

    //////// NAVIGATION ON MOBILE /////////////////////////
    var jPanelMenu = $.jPanelMenu({
        menu: '#responsive',
        animated: false,
        keyboardShortcuts: true
    });
    jPanelMenu.on();

    $(document).on('click',jPanelMenu.menu + ' li a',function(e){
        if ( jPanelMenu.isOpen() && $(e.target).attr('href').substring(0,1) === '#' ) { jPanelMenu.close(); }
    });

    $(document).on('touchend','.mobile-menu-trigger',function(e){
        jPanelMenu.triggerMenu();
        e.preventDefault();
        return false;
    });

    // Removes SuperFish Styles
    $('#jPanelMenu-menu').removeClass('menu');
    $('ul#jPanelMenu-menu li').removeClass('dropdown');
    $('ul#jPanelMenu-menu li ul').removeAttr('style');
    $('ul#jPanelMenu-menu li div').removeClass('mega').removeAttr('style');
    $('ul#jPanelMenu-menu li div div').removeClass('mega-container');


    $(window).resize(function (){
        var winWidth = $(window).width();
        if(winWidth>767) {
            jPanelMenu.close();
        }
    });


    $(".share-list a").on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var a = $(this), href = a.attr('href'), social = a.attr('data-social');

        window.open(href, social, "height=300,width=550,resizable=1");
        return false;
    });

    // AUTOMATICALLY CLOSE ANY MODAL:
    $('.modal .close, #background-overlay').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.modal, #background-overlay').fadeOut(500);
    });

});

function tabNavs_init() {
    // Tabs
    //----------------------------------------//
    var $tabsNav    = $('.tabs-nav'),
        $tabsNavLis = $tabsNav.children('li');
    // $tabContent = $('.tab-content');

    $tabsNav.each(function() {
        var $this = $(this);

        $this.next().children('.tab-content').stop(true,true).hide()
            .first().show();

        $this.children('li').first().addClass('active').stop(true,true).show();
    });

    $tabsNavLis.on('click', function(e) {
        var $this = $(this);

        $this.siblings().removeClass('active').end()
            .addClass('active');

        $this.parent().next().children('.tab-content').stop(true,true).hide()
            .siblings( $this.find('a').attr('href') ).fadeIn();

        e.preventDefault();
    });
}

/**
 * Capitalize first letter. Equivalent of ucfirst for PHP
 * @returns {string}
 */
String.prototype.ucfirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * Whether an object is empty or not
 * @returns {boolean}
 */
//Object.prototype.isEmpty = function() {
//    return !Boolean(Object.keys(this).length);
//};

/**
 * Whether a given Date() is Saturday/Sunday or not.
 * @returns {boolean} True if it's a weekend
 */
Date.prototype.isWeekend = function() {
    return this.getDay() == 0;
};