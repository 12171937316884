var app = angular.module(angularModuleName, [
    //'ngMessages',
    //'ui.bootstrap',
    'toaster',
    'angular-cache',
    'ngSanitize',
    'ngSentry'
])
    /**
     * Global configuration, available
     */
    .constant('Config', config_globals)
    /**
     * Messages for toaster alerts
     */
    .constant('Messages', config_messages)

    /**
     * Routes to be used from AngularJS
     */
    .constant('Routes', config_routes)

    /**
     * Settings for the CacheFactory
     */
    .config(function (CacheFactoryProvider) {
    angular.extend(CacheFactoryProvider.defaults, {
        maxAge: 4 * 60 * 60 * 1000, // 4 hour
        deleteOnExpire: 'aggressive',
        storageMode:'localStorage'
    });
})
    //.directive('ajaxloader', ['Config',function(Config){
    //return {
    //    restrict: 'E',
    //    templateUrl: Config.base_url+'js/templates/ajax_loader.html'
    //};
//}])
.filter('inch', function() {
    return function(input) {
        return String(input).replace(' Inch', '"');
    };
}).run(['CacheFactory','Config','$rootScope','helper', function(CacheFactory,Config,$rootScope,h) {
    if (!angular.isDefined(CacheFactory.get('appCache'))) {
        CacheFactory.createCache('appCache');
    }

    if(!angular.isDefined(CacheFactory.get('appCache').get('hireDays'))){
        CacheFactory.get('appCache').put('hireDays',8);
    }

    $rootScope.load = false;

    h.slowLoader.show();

    // WE DISABLE ALL console.log, but NOT console.info or console.error:
    // console.log = angular.noop;
}]);