app.factory('cart', ['Config','$rootScope','CacheFactory','API_call','toaster','helper',
    function(Config,$rootScope,CacheFactory, API_call,toaster,h) {

        var cartCache = CacheFactory.get('appCache').get('cart') || {},
            cart = {
                content:    cartCache.content    || {},
                totals:     cartCache.totals     || {},
                hireSaving: cartCache.hireSaving || {},
                cartChanged: cartCache.cartChanged || false
            };

        var pq = {}; // global product quantity with fallback for incorrect values, { 23: { old: 11, new: 12 }};

        var factoryFns =  {
            /**
             * Adds a product to the cart
             * @param productToAdd The product
             * @param qty How many products
             * @param callback (optional) A callback function to be called after the product has been added and the prices updated
             * @returns {*}
             */
            add: function(productToAdd, qty, callback) {
                callback = callback || false;
                // Just to make sure it's a valid quantity...
                qty = (Number(qty) > 0) ? Number(qty) : 1;
                // Shortcut for the product ID
                var productToAddID = productToAdd.product_id;

                //check if object exist in cart the trigger an update instead
                var alreadyInCart = Object.keys(cart.content).indexOf(productToAddID + '') !== -1;

                // if it's in the cart, we update the quantity
                if (alreadyInCart) {
                    cart.content[productToAddID + ''].qty += qty;
                    // else: we add it
                } else {
                    cart.content[productToAddID] = {
                        id: productToAddID,
                        data: productToAdd,
                        qty: qty,
                        price: productToAdd.price,
                        subtotal: qty * productToAdd.price
                    };
                }
                // show a success message
                toaster.pop('success', h.message('quote_updated'), h.message('product_added',qty + ' ' + productToAdd.product_name));

                // reset the value to 1:
                pq[productToAddID] = 1;

                // Update the cart
                return factoryFns.updateCart(callback);
            },
            /**
             * Retrieves the cart
             * @returns cart
             */
            getCart: function() {
                return cart;
            },
            /**
             * Removes a given product (ID) from the cart
             * @param productID The ID to remove.
             */
            remove: function(productID) {
                delete cart.content[productID];
                factoryFns.updateCart();
            },
            /**
             * Validates a quantity (from a product input field) and returns a sanitized value: either Number(qty) if
             * it's a valid number, or the value that was before. If "update" is set to true, it will update the cart.
             * @example User enters 4, then -1 -> the last one gets rejected, so the input keeps displaying a 4
             * @param productID The productID that corresponds to the quantity. This is used for indexing purposes
             * @param qty The new quantity
             * @param update Whether to update the cart or not when the value is valid
             * @returns Number
             */
            chgQty:function(productID, qty, update) {
                // We're changing the input manually. If the quantity is empty,
                // we assume the user is entering a new value, so we don't override it.
                if (qty == '') {
                    return '';
                }

                // set valid values for the newQty:
                var numberQty = Number(qty),
                    newQty  = (isNaN(numberQty) || numberQty <= 0) ? (pq[productID] || 1) : numberQty;


                // update the cart, or is it just the quantities (before adding the product)?
                if (newQty !== pq[productID] && (update || 0)) {
                    cart.content[productID].qty = newQty;
                    factoryFns.updateCart();
                }

                // return the sanitized quantity
                pq[productID] = newQty;
                return newQty;
            },
            /**
             * Increases the quantity by 1 in the product input field, and updates the cart if required
             * @param productID The productID that corresponds to the quantity. This is used for indexing purposes
             * @param defaultQty The initial quantity on that field. Used in the checkout page to indicate
             * there are already 6 of product X on the cart, so incQty must set it to defaultQty + 1
             * @param update Whether to update the cart or not when the value is valid
             * @returns Number
             */
            incQty: function(productID, defaultQty, update) {
                // Increase the quantity
                pq[productID] = (pq[productID] || (Number(defaultQty) || 1)) + 1;

                // update the cart, or is it just the quantities (before adding the product)?
                if (update || 0) {
                    //get item from the cart and increment the quantity
                    cart.content[productID].qty = pq[productID];
                    factoryFns.updateCart();
                }

                // return the newly set value
                return pq[productID];
            },
            /**
             * Decreases the quantity by 1 in the product input field, and updates the cart if required
             * @param productID The productID that corresponds to the quantity. This is used for indexing purposes
             * @param defaultQty The initial quantity on that field. Used in the checkout page to indicate
             * there are already 6 of product X on the cart, so incQty must set it to defaultQty - 1
             * @param update Whether to update the cart or not when the value is valid
             * @returns {*}
             */
            decQty: function(productID, defaultQty, update) {
                // Decrease the quantity
                pq[productID] = (pq[productID] || (Number(defaultQty) || 1));

                // decrease if quantity is greater than 1
                if (pq[productID] > 1) {
                    --pq[productID];

                    // update the cart, or is it just the quantities (before adding the product)?
                    if (update || 0) {
                        //get item from the cart and increment the quantity
                        cart.content[productID].qty = pq[productID];
                        factoryFns.updateCart();
                    }
                }

                // return the newly set value
                return pq[productID];
            },

            /**
             * Returns whether the quantity is a valid number or not, i.e. is a NUMBER and >= 1
             * @param qty mixed value
             * @returns {boolean}
             */
            qtyValidation: function(qty) {
                return isNaN(qty) ? false : (Number(qty) >= 1);
            },

            /**
             * Updates the cart, re-constructs the object, saves info to the global scope and DELETES THE QUOTE
             */
            updateCart: function(callback){
                cart.cartChanged = true;
                callback = callback || angular.noop;
                h.loader.show();
                //create an array of cart products and their quantities
                var products = [], productsIndexed = {};
                angular.forEach(cart.content, function(p, productID) {
                    products.push({
                        id: productID,
                        qty: p.qty
                    });
                    productsIndexed[productID] = p;
                });

                cart.content = productsIndexed;

                var hirePeriod = CacheFactory.get('appCache').get('hirePeriod') || {},
                    // If the CacheFactory doesn't have the hireDays, we set it to 8
                    hireDays  = hirePeriod.hireDays  || 8,
                    // If the CacheFactory doesn't have the hireWeeks, we use "1", as it's the neutral product: a*1 = a;
                    hireWeeks = hirePeriod.hireWeeks || 1;

                var data = {
                    'products' :products,
                    'hire-days': hireDays,
                    'brand': Config.brand_key
                };

                if(angular.isDefined(CacheFactory.get('appCache').get('deliveryDate'))){
                    data.deliveryDate = moment(CacheFactory.get('appCache').get('deliveryDate')).format('YYYY-MM-DD');
                }
    
                if(angular.isDefined(CacheFactory.get('appCache').get('collectionDate'))){
                    data.collectionDate = moment(CacheFactory.get('appCache').get('collectionDate')).format('YYYY-MM-DD');
                }

                // They will have to repeat the process if they add something after going through the details page.
                // CacheFactory.get('appCache').remove('quote');

                //send an ajax call to get the new prices
                API_call('cart/update-prices', data, 'POST').then(function(response) {
                    var productTotal = 0,
                        itemsTotal = 0;

                    angular.forEach(cart.content, function (p, productID) {
                        //update the cart with new prices
                        p.price = response.data[productID];

                        p.subtotal = p.qty * p.price * hireWeeks;

                        productTotal += p.subtotal;
                        itemsTotal   += Number(p.qty);
                    });
                    cart.totals.productTotal = parseFloat(productTotal.toFixed(2));
                    cart.totals.items = itemsTotal;

                    // All calls have finished, so we hide the loader
                    h.loader.hide();

                    // This call can be done in the background!
                    API_call('quote/hire-saving', {
                        'hire-days': hireDays,
                        'brand':Config.brand_key,
                        'product-total': cart.totals.productTotal
                    }).then(function(response) {
                        cart.hireSaving = {
                            value: response.data.value,
                            percentage: response.data.percentage
                        };
                        // we update the cart
                        CacheFactory.get('appCache').put('cart',cart);

                        // We call the callback function. It defaults to angular.noop, so won't trigger any error if it didn't exist
                        callback();

                    }, h.ajaxError);
                }, function(response) {
                    // Show a generic error
                    h.ajaxError(response);
                    // hide the loader
                    h.loader.hide();
                });
            },

            /**
             * Clears the cart and (optional) redirects the user to the home page
             * @param redirect (boolean) Redirect or not.
             */
            clear: function(redirect){
                // If we want to redirect, we show a loader (better user experience)
                if (redirect) {
                    h.loader.show();
                    toaster.pop('success', h.message('cart_cleared'));
                }

                // delete everything from the cart!
                cart.content = [];
                cart.totals = {};
                CacheFactory.get('appCache').removeAll();

                // Redirect or not
                if (redirect) {
                    // If set to redirect, it does the redirection after the cart has been updated.
                    h.redirect(redirect);
                }

            },
            /**
             * Calculates the total weight of the cart.
             */
            totalWeight: function(){
                var totalWeight = 0;
                angular.forEach(cart.content, function(p, productID) {
                    //update the cart with new prices
                    totalWeight += Number(p.data.weight) * Number(p.qty);
                });
                return totalWeight;
            },

            /**
             * Whether the items have been quoted or not. (Used to hide the top-nav cart)
             */
            isQuoted: function() {
                return (typeof CacheFactory.get('appCache').get('quote') !== 'undefined');
            }
        };

        return factoryFns;

    }]);
