var config_messages = { // Centralised notification messages for Toaster
    product_added: "{__} added successfully to quote",
    product_removed: "Product {__} removed",
    quote_updated: "Quote updated!",
    discount_code_applied: 'Discount code applied!',
    discount_code_invalid: "The promo code is not valid",
    error_building_query: 'There was an error processing for http_build_query().',
    message_sent: "Message sent!",
    message_sent_description: "We appreciate that you’ve taken the time to write to us. We’ll get back to you very soon.",
    message_sent_no: "Message not sent...",
    message_sent_no_description: "A problem occurred when submitting the message. Please try again and if the problem persists drop us a line.",
    cart_cleared: "The cart has been emptied!",
    cart_not_cleared: "We couldn't empty the cart... please try again",
    quote_error: "There was a problem requesting the quote... server responded with code {__}",
    no_reviews_yet: "No reviews yet",
    postcode_api_error: 'There was a connection problem trying to list the addresses for "{__}"',
    address_no_postcode_entered: "You must enter a valid postcode",
    address_api_error: 'There was a problem when retrieving address information for "{__}"',
    correct_to_next_step: "All the details seem correct. Proceding to the next step...",
    error_loading: "There was a problem on page loading. Please refresh",
    form_invalid: "Please correct the red fields and try again",
    error_ajax: "Oops! Connection error on /{__}",
    processing_payment_title: "Sit tight!",
    processing_payment_body: "We're processing your payment. Please don't close or refresh the page..."
};

var config_routes = { // Centralised routes
    'home': '',
    'checkout-details':  'checkout/details',
    'checkout-quote':    'checkout/quote',
    'checkout-address':  'checkout/address',
    'checkout-delivery': 'checkout/delivery',
    'checkout-payment':  'checkout/payment',
    'process-payment':   'checkout/payment/process',
    'search': 'search?q={q}',
    'product-single': config_globals.products_category + '/{product}',
    'category-single': '{category}'
};

config_globals.craftyclicks = {
    api_url: 'https://api.craftyclicks.co.uk/address/1.1',
    api_key: '8be91-6c930-ca0b3-06bec'
};
config_globals.postcode_regex = "^([a-pA-Pr-uR-UwyzWYZ0-9][a-hA-Hk-yK-Y0-9][aehmnprtvxyoAEHMNPRTVXY0-9]?[abehmnprvwxyo" +
    "ABEHMNPRVWXY0-9]? {0,2}[0-9][abABd-hD-HjlJLn-uN-Uw-zW-Z]{2}|GIR 0AA)$";
config_globals.homepage_main_section = {
    slug: 'new_arrivals',
    name: 'New Arrivals'
};
config_globals.price = {
    period: '/week',
    currency: '£'
};
config_globals.google_conversion_id = 929202074;
config_globals.google_conversion_label = "LAueCP661WYQmv-JuwM";
config_globals.height = 300;
config_globals.width  = 300;
config_globals.featured_product_id = 164;
config_globals.insideWorkingHours=[8,9,10,11,12,13,14,15,16],
config_globals.outsideWorkingHours=[0,1,2,3,4,5,6,7,17,18,19,20,21,22]
config_globals.craftyclicks_api_key = "8be91-6c930-ca0b3-06bec";
config_globals.vatRate = 20;
