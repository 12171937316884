app.controller('TopCartController', ['$scope','Config','cart','toaster','$window', 'helper',
    function($scope,Config,cart,toaster,$window,h){

        $scope.cart = cart.getCart();
        $scope.route = h.route;
        $scope.asset = h.asset;
        $scope.price = h.price;

        /**
         * Given a product (object), removes the given product from the cart and displays a toaster message.
         * @param product
         */
        $scope.remove = function(product){
            // we get the name (to use with the toaster)
            var productName = product.product_name;
            // we remove the product from the cart
            cart.remove(product.product_id);
            // we show the success message
            toaster.pop('success', h.message('quote_updated'), h.message('product_removed', productName));
        };

    }]);