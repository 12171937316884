app.controller('MainController', ['$rootScope','$scope','Config', '$window', '$http', 'toaster', 'API_call', 'helper',
    function($rootScope, $scope, Config, $window, $http, toaster, API_call, helper){

        var requestCallback = { // Request Callback
            name: '',
            telephone: '',
            company: '',
            time: ''
        };

        $scope.base_url = Config.base_url;
        $scope.rc = angular.copy(requestCallback);
        $scope.rc.token = Config.user.token;
        $scope.rcError = {};

        $scope.openCallBackPopup = function() {
            angular.element('#background-overlay, #request_callback').fadeIn(300);
        };

        $scope.requestCallbackSubmit = function($form) {
            if ($scope.rc.name.length <= 3) {
                $scope.rcError.name = true;
                return;
            }
            if ($scope.rc.telephone.length < 8 || $window.isNaN($scope.rc.telephone)) {
                $scope.rcError.telephone = true;
                return;
            }
            if (!$scope.rc.time) {
                $scope.rcError.time = true;
                return;
            }

            $scope.rc.brand = Config.brand_key;

            helper.loader.show();

            API_call('callback/request', $scope.rc, 'POST').then(function(response) {
                console.log(response);
                helper.loader.hide();
                if (response.data.success == 1) {
                    //$scope.rcError.agent   = response.data.responseData.agent;
                    $form.$setPristine();
                    $form.$setUntouched();
                    $scope.rc = angular.copy(requestCallback);
                    $scope.rcError.message = '';
                    angular.element('#background-overlay').trigger('click');
                    toaster.pop({
                        type: 'success',
                        title:'Thank you!',
                        body: response.data.responseData.agent + ' will get in touch with you',
                        tapToDismiss: true,
                        timeout: 6000 // closes after 6 seconds.
                    });
                } else {
                    $scope.rcError.message = response.data.responseData.message;
                }

            }, function(errorResponse) {
                helper.loader.hide();
            });
        }

    }]);