app.factory('helper', ['Messages', '$http', 'Config', 'Routes', '$timeout', '$window', 'toaster',
    function(Messages, $http, Config, Routes, $timeout, $window, toaster) {
        /**
         * Contains alls the helper methods. Always included as "h" for a shortcut: ["helper", function(h) { ... }]
         * @type {{}}
         */
        var helper = {};

        /**
         * Checks whether the given object is empty
         * @param object
         * @returns {boolean} True if empty
         */
        helper.empty = function(object) {
            return (typeof object !== 'object') || ((!Object.keys(object).length) && angular.equals({}, object));
        };

        /**
         * Extends obj2 into obj1. Preserves the values of obj1 if both objects have the same key.
         * The difference between helper.extend and angular.extend is that angular extends empty strings
         * whereas this one checks if the values are undefined.
         * @example {name: 'foo', details: ''} and {name: 'bar', details: 'my name is bar'} would have extended
         * to {name: 'foo', details: 'my name is bar'} with angular.extend. Now it preserves the original.
         * @param obj1
         * @param obj2
         * @returns Object
         */
        helper.extend = function(obj1, obj2) {
            var dest = angular.copy(obj2);
            for (var key in obj1) {
                // first condition has faster computer time, second is a fallback
                if (obj1[key] || typeof obj1[key] !== 'undefined') {
                    dest[key] = obj1[key];
                }
            }
            return dest;
        };
        helper.formatMoney = function(num){
            return accounting.formatMoney(num,{ symbol: "GBP",  format: "%v",decimal:'.', precision:2,thousand:''});
        };
        helper.timedHackCalc= function(transport,surcharges){
            //check if time slot lie in the red zone and double the price
            delTimeStart = parseInt(transport.delivery.timeStart.split(":")[0]);
            colTimeStart = parseInt(transport.collection.timeStart.split(":")[0]);

            //get delivery & collection surcharge price
          if(transport.delivery.option){
              var delSurchargePrice = 0;
              angular.forEach(surcharges.delivery_surcharges, function(surcharge, key) {
                  if(surcharge.carriage_surcharge_id == transport.delivery.option.carriage_surcharge_id){
                      delSurchargePrice = surcharge.price;
                  }
              });
              if(delTimeStart<5 || delTimeStart>20){
                  transport.delivery.option.price = 2*delSurchargePrice;
              }else{
                  transport.delivery.option.price = delSurchargePrice;
              }
          }

          if(transport.collection.option){
              var colSurchargePrice = 0;
              angular.forEach(surcharges.collection_surcharges, function(surcharge, key) {
                  if(surcharge.carriage_surcharge_id == transport.collection.option.carriage_surcharge_id){
                      colSurchargePrice = surcharge.price;
                  }
              });
              if(colTimeStart<5 || colTimeStart>20){
                  transport.collection.option.price = 2*colSurchargePrice;
              }else{
                  transport.collection.option.price = colSurchargePrice;
              }
          }
            return transport;
        };
        helper.getTime = function() {
            return $http({
                method: 'GET',
                url: Config.api_url+'time',
                headers:{
                    'authorization':Config.api_key
                }
              });
          };

        /**
         * Shows a "Redirecting..." message and performs a redirect.
         * @param routeName
         * @returns {boolean} true
         */
        helper.redirect = function(routeName) {
            var toolong = $window.document.getElementById('load-toolong');
            toolong.style.display = 'inline-block';
            toolong.innerHTML = "Redirecting...";

            $window.location.href = helper.route(routeName);
            return true;
        };

        /**
         * message factory: Provides a shortcut to access the constant Messages.
         * This function shows the info messages to users.
         */
        helper.message = function(messageKey, placeholder) {
            placeholder = placeholder || false;
            if (placeholder) {
                // We need to perform a "replace"
                return Messages[messageKey].replace('{__}', placeholder);
            } else {
                // This is a generic message without placeholder
                return Messages[messageKey];
            }
        };

        /**
         * Generates a route for the given route key with the appropriate params.
         * See the Laravel5 route function for more info
         */
        helper.route = function(routeKey, params) {

            if (typeof Routes[routeKey] === 'undefined') {
                console.error("The route '" + routeKey + "' doesn't exist");
                return "#_invalid_route";
            }

            params = params || {};
            this.routeURL = Routes[routeKey];
            angular.forEach(params, function ($v, $k) {
                this.routeURL = this.routeURL.replace('{' + $k + '}', $v);
            }, this);
            return Config.base_url + this.routeURL;
        };
        /**
         * Similar to the Laravel5 asset function. Returns the normal asset for cached files and the media url for non-cached
         * (we know the difference based on the leading slash / )
         * @example /img/placeholder.png WRONG
         * @example  img/placeholder.png OK
         */
        helper.asset = function(path) {
            return path[0] == '/' ?
            Config.media_url + path :
            Config.base_url + path;
        };

        /**
         * Returns a formatted price (e.g. £13.70). If the period parameter is specified, it will be returned as £13.70/week.
         * Both the currency and the period are defined in the /globals.js file.
         * @param price
         * @param period
         * @returns {string}
         */
        helper.price = function(price, period) {
            return Config.price.currency + (Number(price).toFixed(2)) + (!!period ? Config.price.period : '');
        };

        /**
         * The function to show/hide the loader. Defined globally at /scripts/loader.js
         */
        helper.loader = $window.helperLoader;

        /**
         * Controls the slow message for the loader. Defined globally at /scripts/loader.js
         */
        helper.slowLoader = $window.helperSlowLoader;

        /**
         * Formats a date nicely, as in "14 of August, 2019" to display to the user. Requires a Date() object
         * @param date
         * @returns {string}
         */
        helper.niceDate = function(date) {
            var monthNames = [
                "January", "February", "March",
                "April", "May", "June", "July",
                "August", "September", "October",
                "November", "December"
            ];

            var day = date.getDate();
            var month = monthNames[date.getMonth()];
            var year = date.getFullYear();

            return day + ' of ' + month + ', ' + year;
        };

        /**
         * To be called when page has loaded and all Angular is rendered.
         * If there exists a function called jquery_init() it will be called too.
         */
        helper.init = function() {
            $timeout(function() {
                helper.loader.hide();
                if (typeof jquery_init !== 'undefined') {
                    jquery_init();
                }
            }, 100);
        };

        /**
         * Validates an input, to add the class "error".
         * @param form
         * @param property
         * @returns {*}
         */
        helper.errorClass = function(form, property) {
            //console.log(property, form[property], form[property].$touched, form.$submitted, form[property].$invalid)
            return !!Boolean((form[property].$touched || form.$submitted) && form[property].$invalid);
        };
        /**
         * Validates an input, to add the error message.
         * @param form
         * @param property
         * @returns {*}
         */
        helper.errorMessage = function(form, property) {
            return ((form[property].$touched /*&& Object.keys(form[property].$error).length*/) || form.$submitted);
        };

        /**
         * Centralised behaviour for error AJAX calls. When the $http returns an error and thus enters
         * .then(function(responseOK) { "not here" }, function(responseKO) {"enters here"})
         * the second parameter, we process the message here.
         * @param response The response returned by the server
         * @param message custom message, if specified. Defaults to 'error_ajax' message
         */
        helper.ajaxError = function(response, message) {
            var uri = response.config.info.uri || "{empty}";
            console.error("Response for '/" + uri + "':  ", response);
            toaster.pop('error', message || helper.message('error_ajax', uri));
        };
        
        return helper;

    }]);