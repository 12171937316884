var helper_timeout,
    helperLoader = {
        hide: function() {
            helperSlowLoader.hide();
            $('#loader-wrapper, #inner-loader').fadeOut(300);
            $('body').addClass('loaded');
        },
        show: function() {
            helperSlowLoader.show();
            $('#loader-wrapper').fadeTo(300, 0.4, function() {
            });
            $("#inner-loader").fadeTo(300, 1);
            $('body').removeClass('loaded');
        }
    },
    helperSlowLoader = {
        hide: function() {
            clearTimeout(helper_timeout);
            $("#load-toolong").hide();
        },
        show: function() {
            helper_timeout = setTimeout(function() {
                $("#load-toolong").show();
            }, 5000);
        }
    };