app.factory('http_build_query', ['helper', function(h) {
    return function (formdata, numericPrefix, argSeparator) {
        var urlencode = function (str) {
            return encodeURIComponent(str)
                .replace(/!/g, '%21')
                .replace(/'/g, '%27')
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')
                .replace(/\*/g, '%2A')
                .replace(/%20/g, '+')
        };

        var value, key, tmp = [];

        var _httpBuildQueryHelper = function (key, val, argSeparator) {
            var k, tmp = [];
            if (val === true) {
                val = '1'
            } else if (val === false) {
                val = '0'
            }
            if (val !== null) {
                if (typeof val === 'object') {
                    for (k in val) {
                        if (val[k] !== null) {
                            tmp.push(_httpBuildQueryHelper(key + '[' + k + ']', val[k], argSeparator))
                        }
                    }
                    return tmp.join(argSeparator)
                } else if (typeof val !== 'function') {
                    return urlencode(key) + '=' + urlencode(val)
                } else {
                    throw new Error(h.message('error_building_query'))
                }
            } else {
                return ''
            }
        };

        if (!argSeparator) {
            argSeparator = '&'
        }
        for (key in formdata) {
            value = formdata[key]
            if (numericPrefix && !isNaN(key)) {
                key = String(numericPrefix) + key
            }
            var query = _httpBuildQueryHelper(key, value, argSeparator)
            if (query !== '') {
                tmp.push(query)
            }
        }
        return tmp.join(argSeparator)

    }
}])
    //.config([
    //'$httpProvider',
    //function($httpProvider) {
    //
    //    return $httpProvider.interceptors.push(['$q', 'toaster', 'helper', function($q, toaster, h) {
    //        return {
    //            responseError: function(rejection) {
    //                //console.log("Rejection:", rejection);
    //                //alert("rejected!");
    //                 toaster.pop('error',h.message('error_ajax', rejection.config.info.uri));
    //                return $q.reject(rejection);
    //            }
    //        }
    //    }]);
//
//}])
.factory('API_call', ['Config', 'http_build_query', '$http', function(Config, http_build_query, $http) {
    /**
     * Calls the API for the specified uri with the given query parameters.
     * You <b>don't</b> need to add the brand key: it's added here.
     * What this function does is create the URL: <code>Config.api_url + '/' + uri + '?' + http_build_query(queryParams)</code>
     * And then calls the API with the appropriate headers.
     * @param uri The api URI to call. E.g. "product" or "cart/update-prices" (without leading or trailing / )
     * @param queryParams A JSON object with the query params
     * @param method = GET (default)
     * @returns {*}
     * @author Nico Kupfer
     */
    var api_call = function(uri, queryParams, method) {
        method = (method || 'GET').toLowerCase();
        queryParams = (queryParams || {});
        queryParams.brand = Config.brand_key;

        var fullURI = Config.api_url + '/' + uri,
            httpOptions = {
                method: method.toUpperCase(),
                url: fullURI,
                headers:{
                    'authorization':Config.api_key
                },
                info: {
                    uri: uri,
                    query: queryParams
                }
            };

        if (method === 'get') {
            httpOptions.url = fullURI + '?' + http_build_query(queryParams);
            return $http(httpOptions);

        } else if (method === 'post') {
            httpOptions.data = queryParams;
            return $http(httpOptions);
        } else {
            console.error("Wrong method. Use 'get' or 'post'");
        }
    };

    // it's done this way so I could add a doc comment to the function!
    return api_call;
}]);